<template>
  <div class="home">        
    <div class="software-stack-container">
      <div class="stack-wrapper">
        <h3>Languages</h3>
        <p class="sub-heading">Hover for more</p>
        <div class="language-stack">
          <ImageCard v-for="(software, index) in softwareStack" :key="index" :cardObject="software" />          
        </div>
      </div>
      <div class="stack-wrapper">
        <h3>Software and tech</h3>
        <p class="sub-heading">Hover for more</p>
        <div class="software-stack">
          <ImageCard v-for="(software, index) in techStack" :key="index" :cardObject="software" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCard from '@/components/ImageCard.vue'
export default {
  name: 'Home',
  components: {
    ImageCard
  },
  methods: {
    createImageLink(assetName) {
      if (process.env.NODE_ENV === 'development') {
        return `/images/${assetName}`
      }
      return `https://sitedelta.fra1.cdn.digitaloceanspaces.com/sitedelta.com/${assetName}`
    }
  },
  data() {
    return {
      softwareStack: [
        {
          img: this.createImageLink('c-sharp-logo.svg'),
          alt: 'C#',
          title: 'C#',
          text: ['.NET/ASP.Core', '8+ years experience']
        },
        {
          img: this.createImageLink('delphi-logo.png'),
          alt: 'Delphi',
          title: 'Delphi',
          text: ['4+ years experience']
        },
        {
          img: this.createImageLink('kotlin-logo.svg'),
          alt: 'Kotlin',
          title: 'Kotlin',
          text: ['3+ years experience']
        },
        {
          img: this.createImageLink('vue-logo.svg'),
          alt: 'Vue',
          title: 'VueJS Stack',
          text: ['3+ years experience']
        },
        {
          img: this.createImageLink('react-logo.svg'),
          alt: 'React',
          title: 'ReactJS Stack',
          text: ['3+ years experience']
        },
        {
          img: this.createImageLink('typescript-logo.svg'),
          alt: 'TypeScript',
          title: 'TypeScript',
          text: ['3+ years experience']
        },
        {
          img: this.createImageLink('javascript-logo.svg'),
          alt: 'JavaScript',
          title: 'JavaScript',
          text: ['7+ years experience']
        },
        {
          img: this.createImageLink('php-logo.svg'),
          alt: 'PHP',
          title: 'PHP',
          text: ['7+ years experience']
        },
        {
          img: this.createImageLink('html-logo.svg'),
          alt: 'HTML',
          title: 'HTML5',
          text: ['7+ years experience']
        },
        {
          img: this.createImageLink('css-logo.svg'),
          alt: 'CSS',
          title: 'CSS3',
          text: ['7+ years experience']
        }        
      ],
      techStack: [
        {
          img: this.createImageLink('visual-studio-code-logo.svg'),
          alt: 'VS Code',
          title: 'VS Code',
          text: ['All things Web and misc scripting']
        },
        {
          img: this.createImageLink('visual-studio-logo.svg'),
          alt: 'Visual Studio',
          title: 'Visual Studio',
          text: ['C# & ASP.Core']
        },
        {
          img: this.createImageLink('mysql-logo.svg'),
          alt: 'MySQL',
          title: 'MySQL',
          text: ['Web Services', '7+ years experience']
        },
        {
          img: this.createImageLink('postgresql-logo.svg'),
          alt: 'PostgreSQL',
          title: 'PostgreSQL',
          text: ['Backends and desktop apps', '4+ years experience']
        },
        {
          img: this.createImageLink('ubuntu-logo.svg'),
          alt: 'Ubuntu',
          title: 'Ubuntu',
          text: ['Server hosting', '7+ years experience']
        },
        {
          img: this.createImageLink('docker-logo.svg'),
          alt: 'Docker',
          title: 'Docker',
          text: ['1+ year experience']
        },
        {
          img: this.createImageLink('intellij-logo.svg'),
          alt: 'IntelliJ',
          title: 'IntelliJ',
          text: ['Kotlin, Maven']
        },
        {
          img: this.createImageLink('apache-logo.svg'),
          alt: 'Apache',
          title: 'Apache',
          text: ['Web hosting with LAMP stacks']
        },
        {
          img: this.createImageLink('caddy-logo.svg'),
          alt: 'Caddy',
          title: 'Caddy',
          text: ['Favorite lightweight web server at the moment!']
        },
        {
          img: this.createImageLink('git-logo.svg'),
          alt: 'Git',
          title: 'Git & Github',
          text: ['6+ years experience']
        },
        {
          img: this.createImageLink('jenkins-logo.svg'),
          alt: 'Jenkins',
          title: 'Jenkins',
          text: ['3+ years experience']
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.software-stack-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;  

  .stack-wrapper .sub-heading {
    font-size: 0.85em;
    margin-bottom: 20px;
  }

  .software-stack, .language-stack {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    gap: 80px;
    margin-bottom: 130px;
  }  
}

@media screen and (max-width: 660px) {
  .software-stack-container {
    flex-direction: row;
    padding: 0 40px;
    .software-stack, .language-stack {      
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
</style>
