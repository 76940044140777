<template>
  <div class="content-wrapper">
  <header id="about-header">
    <h2>Full stack developer</h2>
    <span>
      <h4>Based in Sweden</h4>      
      <img src="/images/sweden-flag.png">
    </span>
    <h6>Contact: <a href="mailto:rasmus@sitedelta.com">rasmus@sitedelta.com</a></h6>
  </header>
  <nav id="nav">
    <router-link to="/">Home</router-link>
    <router-link to="/projects">Projects</router-link>
    <span/>
  </nav>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
  </div>
  <footer>
    &#169; 2022 Rasmus
  </footer>
</template>

<style lang="scss">

html, body, #app{
  min-height: 100%;
  min-width: 100%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: var(--background-primary);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--font-color);
  display: grid;
  grid-template-rows: 1fr 40px;
}

#about-header {
  padding: 80px 80px;
  height: 220px;
  text-align: left;    
  background: var(--header-bg);
  background: var(--header-bg-gradient);
  background: url('/images/header-background3.png') no-repeat;
  background-size: cover;
  text-shadow: 2px 2px #000;
  a {
    color: var(--link-color);
    text-decoration: none;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  img {
    margin-left: 5px;
    height: 20px;
  }
}

.nav-dummy {
  width: 100%;
  background: var(--background-main);  
  height: 110px;
  display: block;
}

#nav {
  width: 100%;
  position: sticky;
  top: 0;
  left: 0;
  background: var(--background-main);
  display: block;
  padding: 10px 20%;  
  align-self: flex-start;
  margin-bottom: 120px;
  /*grid-template-rows: 100%;
  grid-template-columns: 80px 80px;
  gap: 20px;*/
  z-index: 10;

  & > a {
    font-size: 1.3em;
    font-weight: bold;
    color: var(--nav-font-color);
    text-decoration: none;
    width: 100%;
    max-width: 100px;
    margin-right: 60px;

    &:last-of-type {
      margin-right: 0;
    }

    &.router-link-exact-active {
      border-bottom: 1px solid var(--nav-font-color);
    }
  }
}

footer {
  background: var(--background-main);
  padding: 10px;
}

@media screen and (max-width: 600px) {
  #nav {
    grid-template-columns: 80px 80px;    
    .nav-heading {
      a {
        font-size: 1em;
      }
    }
  }
}
</style>
