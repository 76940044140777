<template>
  <span class="card">
    <img :src="cardObject.img" :alt="cardObject.alt">
    <span class="hidden-content">
      <p class="title">{{cardObject.title}}</p>
      <p v-for="(text, index) in cardObject.text" :key="index">
        {{text}}
      </p>
    </span>
  </span>
</template>

<script>
export default {
  name: 'ImageCard',
  props: {
    cardObject: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 130px;      
    padding: 20px 20px 5px 20px;
    position: relative;
    transition: background 0.5s;
    img { 
      height: 60px;
      width: 60px;
    }

    .title {
      font-weight: bold;
    }

    .hidden-content {        
      padding: 10px 10px 30px 10px;
      background: #FFF;
      color: #000;
      display: inline-block;
      opacity: 0;
      transition: opacity 0.5s;
      position: absolute;
      top: 85px;
      width: 130px;
      z-index: -1;   
    }

    &:hover {
      background: #FFF;    
      .hidden-content {
        z-index: 2;           
        opacity: 100;
        //margin-top: 65px;
        /*border-top: 2px solid rgb(13, 118, 238);*/
      }
    }
  }
</style>
